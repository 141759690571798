// footer
.footer-widgets,.site-footer
  background: transparent
  .widget-area
    .widget
      margin: 0

#footer-widgets
  padding: 0
  .container
    +tablet
      width: calc(100% - 60px)
  .footer-widget.widget_nav_menu
    padding: 0
    margin: 0
    position: relative
    &:before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 50%
      transform: translateX(-50%)
      background-color: $main
      +size(100vw,100%)
    .menu
      display: flex
      justify-content: center
      margin: 0
      position: relative
      z-index: 2
      +tablet
        display: block
        padding: 20px 0
      // default
      li
        margin: 0
        a
          display: block
          text-decoration: none
          +font(1,#fff,0)
      // 第一層
      &>li
        +tablet
          margin-right: 0
          border-bottom: solid 1px rgba(#fff,.5)
          &:last-of-type
            border-bottom: none
        &.icon
          display: none
        &.wpmenucartli,
        &.logout,
        &.member
          display: none
        &.menu-item-has-children
          &>a
            +tablet
              display: flex
              justify-content: space-between
            &:after
              content: "+"
              font-style: inherit
              -webkit-font-smoothing: antialiased
              -moz-osx-font-smoothing: grayscale
              display: inline-block
              text-rendering: auto
              font-weight: 400
              display: none
              +tablet
                display: block
          &.open
            &>a:after
              content: "-"
            .sub-menu
              +tablet
                display: block
          &:hover
            .sub-menu
              +min(768px)
                display: block
        &:last-of-type
          margin-right: 0
        &>a
          padding: 15px 35px
          // border-bottom: solid 1px #333
          +tablet
            margin-bottom: 0
            padding: 10px 0
            border-bottom-color: #ccc
        // 第二層
        ul.sub-menu
          position: absolute
          bottom: 100%
          left: 50%
          transform: translateX(-50%)
          background-color: #fff
          width: 170px
          display: none
          +tablet
            display: none
            position: relative
            bottom: 0
            left: 0
            transform: none
            width: 100%
          >li
            margin: 0
            >a
              padding: 10px 15px
              color: $black
              &:hover
                background-color: $main
                color: #fff

  .contact-info,
  .social-links
    position: relative
    text-align: center
    &:before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 50%
      transform: translateX(-50%)
      background-color: $gray
      +size(100vw,100%)
    >*
      position: relative
      z-index: 2
  .contact-info
    padding-top: 25px
    h3,p,a
      +font(0.875,#fff,0)
        line-height: 1.5

  .social-links
    padding: 15px 0 25px
    a
      display: inline-block
      margin: 0 10px
      img
        display: block
        +size(30px,auto)

  .copyright
    position: relative
    padding: 10px 0
    >*
      position: relative
      z-index: 2
    &:before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 50%
      transform: translateX(-50%)
      background-color: $black
      +size(100vw,100%)
    p
      +font(0.875,#fff,0)
      text-align: center
      a
        color: #fff

  button#go-top
    position: absolute
    z-index: 3
    right: 0%
    bottom: 110px
    background: none
    border: none
    outline: none
    +phone
      bottom: 60px
    img
      +size(50px,auto)
      +phone
        +size(30px)

.float-links
  position: fixed
  z-index: 10
  top: 40%
  right: 30px
  +phone
    right: 5px
  li
    margin-bottom: 15px
    list-style-type: none
    +size(50px)
    +phone
      margin-bottom: 10px
      +size(30px)
    &:last-of-type
      margin-bottom: 0
    a
      display: block
      height: auto
      img,svg
        width: 100%
        path
          +t(.3)
      &:hover
        svg
          >path:first-of-type,
          >g:first-of-type>path:first-of-type,
          >g:first-of-type>g:first-of-type>path:first-of-type
            fill: $orange2