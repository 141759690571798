// 文章
body.archive.category,body.search.search-results:not(.woocommerce-page)
  #content-inside
    +container
    display: flex
    flex-wrap: wrap
    padding-top: 50px
    +tablet
      padding-top: 30px
  #primary
    width: calc(100% - 250px)
    padding-left: 50px
    +tablet
      width: 100%
      padding-left: 0

  #main    
    display: flex
    flex-wrap: wrap

  .post
    width: 100%
    +tablet
      padding: 0
      width: calc(50% - 8px)
      border: none
      +mini
        width: 100%
        padding-bottom: 30px
        margin-bottom: 30px
        border-bottom: solid 1px rgba(#000,.1)
        &:last-of-type
          margin-bottom: 0
      &:nth-of-type(odd)
        margin-right: 16px
        +mini
          margin-right: 0
    .list-article-thumb
      display: block
      margin-right: 0
      width: 30%
      +tablet
        width: 100%
        margin-bottom: 15px
      img,a
        display: block
        width: 100%
    .list-article-content
      width: 70%
      padding-left: 30px
      +tablet
        width: 100%
        padding-left: 0
    .list-article-meta
      margin-bottom: 0
      a
        +font(.9,$main,0)
        font-weight: normal
      span.date
        display: block
        +font(.85,$gray,0)
        font-weight: normal
    .entry-header
      margin-bottom: 10px
    .entry-title
      a
        +font(1.3,$text,.01)
        font-weight: bold
    .entry-excerpt,.entry-summary
      p
        +font(.9,$gray,.05)
      .clearfix
        +clearfix
      a.read-more
        text-transform: capitalize
        display: block
        margin-top: 5px
        float: right
        text-decoration: none
        +font(.8,$black,0)
        &:hover
          color: $main

.wp-pagenavi
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  width: 100%
  padding: 40px 0 50px
  *
    +font(1,$black,0)
  span:not(.pages),a:hover
    background-color: $main
    color: #fff
  span:not(.pages),a
    display: block
    margin: 0 3px
    +size(25px)
    border-radius: 50%
    text-align: center
    text-decoration: none
    line-height: 25px
    +t(.3)
  span.pages
    margin-right: 15px
  a.nextpostslink
    margin-left: 10px
  a.previouspostslink
    margin-right: 10px


body.single-post
  #content-inside
    +container
    padding: 50px 0
    +tablet
      padding: 30px 0

  .entry-header
    .entry-title
      +font(1.7,$black,.2)
    .byline
      display: none

  .entry-footer,.entry-meta
    span,a
      +font(.9,$gray,.05)
      font-weight: normal
      
  .entry-content
    section.kc_row
      padding: 0
      .kc-row-container.kc-container
        width: 100%

  .relpost-thumb-wrapper
    float: left
    left: -6px
    position: relative
    width: calc( 100% + 12px )
    h3
      padding: 6px
      margin-bottom: 10px
      font-weight: normal
    .relpost-block-container
      width: 100%
      float: left
      a.relpost-block-single
        width: calc(100% / 3 - 0.1px)
        background: none
        +phone
          margin-bottom: 20px
          padding-right: 0
          width: 100%
      .relpost-custom-block-single,
      .relpost-block-single-image
        min-width: 100%
        height: auto!important
      .relpost-custom-block-single
        margin: 0
      .relpost-block-single-image
        padding-bottom: 63%
        background-size: cover!important
        background-position: center center!important
      .relpost-block-single-text
        padding-top: 10px
        text-align: left
        +font(0.9,$black,0)

.post-btns
  display: flex
  justify-content: space-between
  &>div
    width: 48%
    a
      display: block
      +font(1,$black,0)
      text-decoration: none
      span
        display: block
    &.next
      text-align: right




// 左側
.menu-toggle-btn
  +font(.9,#fff,0)
  display: none
  line-height: normal
  padding: 8px 3px
  text-align: center
  position: fixed
  left: 0
  top: 40%
  width: 25px
  background-color: $main
  border: none
  outline: none
  +t(.5)
  z-index: 999
  +tablet
    display: block
  span:last-of-type
    display: none
  &.toggle
    left: 250px
    z-index: 999999
    span
      display: none
    span:last-of-type
      display: block

#secondary
  padding: 0
  width: 250px
  position: relative
  z-index: 2
  +t(.5)
  +tablet
    position: fixed
    padding: 30px 20px
    background-color: #fff
    height: 100%
    top: 0
    left: -250px
    overflow: auto
    &::-webkit-scrollbar
      display: none
    &.in
      left: 0
      z-index: 999999
  .widget
    margin-bottom: 30px
  .widget-title
    +font(1.2,$gray,.05)
      font-weight: 500
      margin-bottom: 20px
      border-left: solid 3px $gray
      padding-left: 10px
  .product-categories,.widget_categories>ul
    margin: 0
    padding: 0
    &>li
      width: 100%
      &>a
        border-bottom: solid 1px #eee
    li
      margin: 0
      list-style-type: none
      a
        +font(1,$black,.05)
          display: block
          padding: 5px 0px
          text-decoration: none
          +t(.3)
          &:hover
            color: $main
      &.cat-parent
        cursor: pointer
        position: relative
        &>a
          +clearfix
        &>span
          +font(1,$black,.05)
            display: block
            padding: 5px 0
            position: absolute
            top: 0
            right: 5px
            width: 30px
            text-align: center
          &:after
            content: "+"
      &.current-cat
        &>a,&>span
          color: $main
      &.current-cat-parent
        &>span:after
          content: "-"
        &>.children
          // display: block
      .children
        display: none
        padding: 10px 0
        min-height: 1px
        li
          a
            +font(.9,$black,0)
            padding: 0
            &:hover
              color: $main
            &:before
              content: "-"
              margin-right: 5px
          &.current-cat
            &>a
              color: $main

  .widget_recently_viewed_products
    .product_list_widget
      li
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        a
          display: flex
          align-items: flex-start
          width: 100%
          margin-bottom: -20px
          span
            display: block
            padding-bottom: 20px
            +font(.8,$black,0)
            font-weight: normal
            margin-left: 10px
            width: calc(100% - 60px)
          img
            display: inline-block
            position: relative
            float: none
            +size(50px,auto)
        // del,ins,.amount
        //   margin-top: -20px
        ins
          text-decoration: none
          *
            color: $special

  .widget_recent_entries
    ul
      li
        margin: 0
        padding: 5px 0
        a
          +font(1,$black,0)
        .post-date
          display: inline-block
