
[id^="module-pg-product"]
  .product-row
    +tablet
      margin-left: -10px
      width: calc(100% + 20px)
    >div
      +tablet
        padding: 0 10px 20px
        width: 50%

[id^="module-pg-info-product"]
  .swiper-slide
    img
      display: block
      width: 100%
  .product-top
    margin-bottom: 20px

  .product-thumbs
    .swiper-slide
      cursor: pointer

  .product-carousel
    padding-right: 35px
    +tablet
      padding-right: 0
      padding-top: 30px


  .product-info
    border-bottom: solid 1px $blue
    padding-bottom: 60px
    margin-bottom: 50px
    .kc_text_block
      h2
        margin-bottom: 10px
        +font(1.5,$gray,0)
          line-height: normal
      h4
        +font(1.125,$gray,0)
          line-height: normal
      h5
        display: flex
        margin-bottom: 10px
        img
          margin-right: 10px
          +size(45px)
          &:last-of-type
            margin-right: 0
      h6
        margin-top: 15px
        a
          display: inline-block
          padding: 10px 20px
          margin-right: 5px
          width: fit-content
          border-radius: 4px
          background-color: $orange2
          +font(1,#fff,0)
            line-height: normal
            text-transform: capitalize
            text-decoration: none
          &:after
            content: ""
            display: inline-block
            margin-left: 15px
            margin-bottom: 2px
            +size(8px)
            border-top: solid 2px #fff
            border-right: solid 2px #fff
            transform: rotate(45deg)
          img
            margin-left: 10px
          &[target]
            &:after
              display: none
      ul
        margin: 20px 0
        padding: 0
        li
          margin: 0 0 0 12px
          list-style-type: "-  "
          +font(1,$gray,0)
            line-height: 1.5rem
        

  .kc_text_block.product-detail
    h4
      margin-bottom: 15px
      +font(1.375,$gray,0)
    p
      margin-bottom: 30px
      +tablet
        margin-bottom: 15px
      img
        display: block
        width: 100%
    ul
      margin: 0 0 50px
      padding: 0
      +tablet
        margin-bottom: 30px
      li
        margin: 0 0 0 12px
        list-style-type: "-  "
        +font(1.125,$gray,0)
          line-height: 1.5rem