
// 預設值
*
  font-family: $font-normal,'Microsoft Jhenghei', sans-serif

textarea:not(.editor-post-title__input):focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus
  border-bottom-color: #efefef!important

input[type="radio"], input[type="checkbox"]
  margin-left: 0

select,.woocommerce-ordering select
  appearance: none
  padding: 5px 35px 5px 15px
  +font(.85,$gray,.1)
  background-image: url(/wp-content/uploads/down-filled-triangular-arrow.svg)
  background-size: 10px auto
  background-repeat: no-repeat
  background-position: calc(100% - 15px) center

h1,h2,h3,h4,h5,h6,p
  margin: 0
  font-family: $font-normal,'Microsoft Jhenghei', sans-serif

.container
  margin: 0 auto

html
  font-size: 16px
  +phone
    font-size: 14px
  +mini
    font-size: 12px


body
  // animation: fade .5s 1s both
  +mask
  &.overview-hide
    overflow: hidden
  &:not(.home)
    #content
      padding: 70px 0
      +tablet
        padding-bottom: 50px

#page
  overflow: hidden

.loading
  position: fixed
  top: 0
  left: 0
  +size(100%)
  background-color: #fff
  z-index: 1000
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .bar
    margin-bottom: 15px
    +size(150px,14px)
    background-color: #fff
    border: solid 1px $main
    border-radius: 50px
    position: relative
    &:after
      content: ""
      display: block
      +size(0px,8px)
      position: absolute
      top: 2px
      left: 2px
      background-color: $main
      border-radius: 50px
      +t(1)
      transition-timing-function: linear
  p
    color: $black
    font-size: 12px
    letter-spacing: 2px
  &.end
    .bar
      &:after
        width: 144px

.site-header .container
  padding: 0

.container,
.site-header .container
  +container

.container,
.kc-col-container
  +clearfix

.breadcrumbs
  span[property="name"]
    +font(.85,$black,0)
    font-weight: normal
    &.current-item
      color: $main

#content-inside
  max-width: 100%
  width: 100%
  // +mobile
  //   padding-top: 70px

#content
  min-height: 0
  +t(.5)
  +tablet
    padding-top: 70px

#primary
  padding: 0

.entry-content
  margin-bottom: 0


// kim
.kc-container
  padding: 0

.kc-container,
.kc_column, .kc_column_inner
  min-height: 0

// .kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner,
// .kc_row.kc_row_inner
  // width: 100%
  // margin: 0

#welcome
  display: flex
  align-items: center
  justify-content: center
  padding: 100px 0
  &>div
    +container
  
  .kc_text_block
    text-align: center

.site-content
  border-top: none


// 頁面標題
.page-header
  .container
    *:last-child
      margin-bottom: 0
  .entry-title
    margin-bottom: 10px
    font-size: 25px
    // font-weight: 500
    letter-spacing: 5px
    line-height: normal
    +tablet
      font-size: 20px
      letter-spacing: 3px
  .entry-tagline
    font-style: normal
    p
      font-size: 16px
      margin: 0
      letter-spacing: 3px
      +tablet
        font-size: 14px
      +phone
        font-size: 12px
        letter-spacing: 1px
  &.page--cover
    display: flex
    flex-direction: column
    justify-content: center
    padding: 50px 0
    background-size: cover
    min-height: calc(100vw / 3)
    .entry-title
      margin-bottom: 10px
      font-size: 40px
      font-weight: 500
      letter-spacing: 5px
      line-height: normal
      +tablet
        margin-bottom: 5px
        font-size: 30px
      +phone
        font-size: 20px
    .entry-tagline
      font-style: normal
      p
        margin: 0
        letter-spacing: 3px

// 區塊框架
.bg
  background-color: rgba($gray,.2)

// 手機版、電腦版
*.mobile
  display: none
  +tablet
    display: block
*.computer
  +tablet
    display: none

[id^="module-"]
  padding: 40px 0
  +tablet
    padding: 30px 0
  .kc-row-container.kc-container
    +container
  &.no-padding-bottom
    padding-bottom: 0
  &.no-padding-top
    padding-top: 0
  &.full-container
    .kc-row-container.kc-container
      max-width: 100%
      width: 100%
  
[id^="module-pg"]
  padding: 65px 0
  +tablet
    padding: 30px 0
  .kc-container.kc-row-container
    width: calc(100% - 340px)
    +mobile
      width: calc(100% - 200px)
    +tablet
      width: calc(100% - 80px)

// #tab-description
// .woocommerce-product-details__short-description
.kc_text_block.default
  h1
    +font(2,$black,.25)
      padding: 30px 0
    img
      // margin-top: 30px
      // margin-bottom: 30px
      max-width: 100%
      &.full
        width: 100%
  h2
    +font(1.875,$black,0)
      margin-bottom: 25px
      font-family: $font-title
    &+h3
      +font(1.25,$gray,.1)
        padding-bottom: 0
        margin-top: -15px
        font-weight: normal
  h3
    +font(1.5,$black,.15)
      margin-bottom: 25px
  h4
    +font(1.3,$black,.1)
      margin-bottom: 15px
  h5
    +font(1.1,$black,.1)
      margin-bottom: 15px
  h6
    +font(1,$black,.1)
      margin-bottom: 15px
  p,li
    +font(1.125,$text,.06)
      font-weight: 300
  p
    margin-bottom: 15px
    &+*:not(p)
      margin-top: 30px
  &.white
    *
      color: #fff
  &.more
    h6
      margin-top: 50px
      margin-bottom: 0
      a
        // display: flex
        // align-items: center
        display: inline-block
        padding: 10px 20px
        width: fit-content
        border-radius: 4px
        background-color: $orange2
        +font(1,#fff,0)
          line-height: normal
          text-transform: capitalize
          text-decoration: none
        &:after
          content: ""
          display: inline-block
          margin-left: 15px
          margin-bottom: 2px
          +size(8px)
          border-top: solid 2px #fff
          border-right: solid 2px #fff
          transform: rotate(45deg)

.kc_text_block.title
  margin-bottom: 35px
  h2
    padding: 0 30px
    margin: 0 auto
    width: fit-content
    white-space: nowrap
    position: relative
    +font(1.875,$gray,0)
      line-height: normal
    +tablet
      padding: 0 15px
    &:after,&:before
      content: ""
      display: block
      position: absolute
      top: 50%
      left: 100%
      transform: translateY(-50%)
      background-color: $main
    &:before
      +size(16px)
      +t(0.5)
      border-radius: 50%
      transform: scale(0) translateY(-50%)
    &:after
      transition-delay: 0.4s
      +size(0,1px)
      +t(3)
    &.start
      &:before
        transform: scale(1) translateY(-50%)
      &:after
        width: 100vw
  &.left
    h2
      margin: 0 0 0 -30px
      +tablet
        margin-left: 0px
      &:after,&:before
        left: auto
        right: 100%
  &.line-left
    h2
      &:after,&:before
        left: auto
        right: 100%
  &.line-right
    h2
      &:after,&:before
        left: 100%
        right: auto
  &.white
    h2
      color: #fff
      &:after,&:before
        background-color: #fff

.kc_text_block.list
  padding-right: 25px
  +tablet
    padding-right: 0
    margin-bottom: 40px
    &:after
      content: "scroll >>>"
  ul
    margin: -15px 0 0
    padding: 0
    +tablet
      display: flex
      justify-content: flex-start
      padding-left: 30px
      padding-right: 30px
      margin-top: -30px
      overflow-x: scroll
      margin-left: -40px
      width: calc(100% + 80px)
      background-color: $main
      &::-webkit-scrollbar
        height: 3px
      &::-webkit-scrollbar-track 
        box-shadow: inset 0 0 20px #fff
        border-radius: 10px
      &::-webkit-scrollbar-thumb 
        background: #f68406
        border-radius: 10px
    li
      padding: 0
      margin: 0
      list-style-type: none
      +font(1.125,$gray,0)
      +t(.3)
      border-bottom: solid 1px $blue
      +tablet
        margin: 0 10px
        border: none
        color: #fff
        flex-shrink: 0
      a
        display: block
        padding: 15px 0
        color: $gray
        text-decoration: none
        +t(.3)
        +tablet
          padding: 10px 0
          color: #fff
        &[href="#active"]
          color: $orange2
      &:hover
        color: $orange2
        a
          color: $orange2

.kc_text_block.product-info
  margin-bottom: 35px
  +tablet
    margin-bottom: 0
  h5
    a
      display: block
      padding: 5px 5px 20px
      background-color: $blue
      text-decoration: none
      text-align: center
      +t(.3)
      +font(1.375,#fff,0)
        font-family: $font-title
        font-weight: bold
      img
        display: block
        width: 100%
        margin-bottom: 20px
      &:hover
        background-color: $orange2

.kc_text_block.timeline
  ul
    padding: 0
    margin: 0
    position: relative
    &:before
      content: ""
      display: block
      +size(1px,100%)
      position: absolute
      background-color: $main
      left: 65px
      transform: translateX(-50%)
      top: -15px
    li
      display: flex
      margin: 0
      list-style-type: none
      strong
        display: block        
        min-width: 50px
        margin-right: 40px
        white-space: nowrap
        position: relative
        font-weight: 300
        &:before
          content: ""
          display: block
          +size(10px)
          background-color: $main
          border-radius: 50%
          position: absolute
          left: calc(100% + 10px)
          top: 10px
          +phone
            top: 8px
          +mini
            top: 5px

.kc_row_inner
  display: flex
  flex-wrap: wrap
  >div
    +tablet
      padding-bottom: 40px
    &:last-of-type
      +tablet
        padding-bottom: 0
  &.reverse
    flex-direction: row-reverse

.kc-video-inner
  position: relative
  padding-bottom: 56.2%
  iframe
    position: absolute
    top: 0
    left: 0
    +size(100%!important)

.kc-wrap-columns
  +clearfix

// 搜尋樣式
.search-form,.woocommerce-product-search
  display: flex
  label
    display: block
    width: calc(100% - 40px)
    flex-grow: inherit
    -webkit-box-flex: inherit
    &.screen-reader-text
      display: none

  input[type="search"]
    padding: 8.5px 15px
    +font(1,$black,0)
    width: calc(100% - 40px)
    outline: none
  [type="submit"]
    margin: 0
    padding: 0
    font-size: 0
    +size(40px)
    background-color: $main
    border: none
    background: $main url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center
    background-size: 20px auto
    &:hover
      background: $main url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center
      background-size: 20px auto
      opacity: 1
      
div.pp_pic_holder.pp_pic_holder
  top: 50%!important
  left: 50%!important
  transform: translate(-50%,-50%)
  position: fixed
  width: auto!important
  max-width: 900px
  img,.pp_content,.pp_hoverContainer,.pp_details
    display: block
    width: 100%!important
    height: auto!important
    max-height: 80vh
    max-width: 100%
    object-fit: contain
  a
    text-decoration: none

.pp_content_container.pp_content_container
  .pp_left,.pp_right
    padding-left: 0
    padding-right: 0

.fancybox-slide--video
  .fancybox-content
    margin: 0
    height: 0
    max-height: 0
    position: relative
    padding-bottom: 45%
    iframe
      position: absolute
      top: 0
      left: 0    

.c4wp-display-captcha-form.c4wp-display-captcha-form
  display: flex
  flex-direction: row-reverse
  justify-content: flex-end
  position: relative
  width: 200px
  br,strong
    display: none
  .refresh_captcha
    display: block
    +size(15px)
    position: absolute
    left: calc(100% + 5px)
    bottom: 50%
    transform: translateY(50%)
    img
      display: block
      margin: 0
      float: none
      +size(100%,auto )
  input[type="text"].c4wp_user_input_captcha
    margin: 0
    padding: 10px
    font-size: 1rem
    width: 100px
    &:focus
      outline: none

.owl-theme .owl-controls,
.swiper-pagination
  margin-top: 20px
  display: flex
  justify-content: center
  flex-wrap: wrap
  width: 100%
  .owl-page span,>span
    margin: 5px
    background-color: #C4C4C4
    opacity: 1
  .owl-page.active span,
  >span.swiper-pagination-bullet-active
    background-color: $orange2
    position: relative
    transform: scale(0.75)
    &:after
      content: ""
      display: block
      +size(100%)
      position: absolute
      right: 50%
      bottom: 50%
      transform: translate(50%,50%) scale(1.5)
      border: solid 1px $orange2
      background: transparent
      border-radius: 50%

[id^="module-banner"]
  padding: 0
  img
    display: block
    width: 100%
    // +tablet
    //   min-height: 250px
    //   object-fit: cover
  .kc_single_image
    position: relative
    p
      padding: 5px 20px
      margin: 0
      position: absolute
      min-width: 200px
      bottom: 50%
      right: 50%
      transform: translate(50%,50%)
      text-align: center
      background-color: #fff
      border: solid 2px $darkBlue
      width: fit-content
      +font(1.5,$gray,0)
        font-family: $font-title
        line-height: normal
        font-weight: bold
      &:after
        content: ""
        display: block
        position: absolute
        left: 100%
        bottom: 50%
        transform: translate(-10px,50%)
        +size(35px,2px)
        background-color: $darkBlue

[id^="module-pg-bottom"]
  margin-bottom: -70px
  img
    display: block
    width: 100%

.wpcf7 .wpcf7-response-output
  width: 100%
  text-align: center
  margin: 0 auto