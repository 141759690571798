
[id^="module-idx-products"]
  .kc-container.kc-row-container
    max-width: 1000px
  .kc_single_image
    margin: 0 auto
    float: none
    max-width: 125px
    +clearfix
    a,img,svg
      display: block
      width: 100%
    a
      // margin-bottom: 20px
      padding-bottom: 20px
    p
      margin: 0
      pointer-events: none
      +font(1.125,$black,0)
        line-height: normal
        text-align: center
    svg
      circle,>g:first-of-type>path:first-of-type
        +t(.3)
    &:hover
      svg
        circle,>g:first-of-type>path:first-of-type
          fill: $orange2
  .kc_row_inner
    display: flex
    >div
      +animate_delay(4,0.1)
      +tablet
        width: 50%
        +animate_delay(2,0.1)
        
[id^="module-idx-about"]
  .kc_single_image
    height: 100%
    img
      display: block
      width: 100%
      height: 100%
      object-fit: cover
  .kc_text_block
    padding-left: 180px
    position: relative
    z-index: 2
    +tablet
      padding: 0
    &.title.left
      h2
        +tablet
          margin-left: -30px
  .kc_row_inner
    >div:first-of-type
      position: relative
      +tablet
        padding-bottom: 0
      .kc-col-inner-container
        display: flex
        justify-content: center
        flex-direction: column
        padding: 50px 0
        +tablet
          padding: 50px 30px 50px 70px
          background-image: linear-gradient(to left,#4D6EB2 0%,#11337B 100%)
      &:before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        +size(60vw,100%)
        z-index: 2
        background-image: url(/wp-content/uploads/about_us-bg.png)
        background-size: 100% 100%
        background-position: right top
        +tablet
          display: none

[id^="module-idx-contact"]
  background-image: url(/wp-content/uploads/contact_us-bg.png)
  background-repeat: no-repeat
  background-size: cover
  background-position: center center

[id^="module-idx-product-carousel"]
  padding-bottom: 80px
  background-image: url('/wp-content/uploads/product-bg.png')
  background-size: cover
  position: relative
  z-index: 2

  .container
    max-width: 960px

  .swiper-container
    margin-top: -80px
    padding-top: 80px
    +tablet
      margin-left: -10px
      width: calc(100% + 20px)

  .swiper-button-next,
  .swiper-button-prev
    margin-top: 20px
    +size(20px,30px)
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    &:after
      display: none
    +mobile
      display: none
  .swiper-button-next
    right: -100px
    background-image: url('/wp-content/uploads/arrow-right.svg')

  .swiper-button-prev
    left: -100px
    background-image: url('/wp-content/uploads/arrow-left.svg')

  .swiper-slide
    padding: 0 15px
    width: calc(100% / 3)
    +t(.4)
    +tablet
      width: 50%
      padding: 0 10px
    &.swiper-slide-next
      transform: translateY(-80px)
      +tablet
        transform: none
    h5
      img
        display: block
        margin-bottom: 20px
        width: 100%
      a
        display: block
        padding: 10px 10px 25px
        background-color: $blue
        border-radius: 2px
        +t(.4)
        +font(1.375,#fff,0)
          line-height: normal
          text-align: center
          text-decoration: none
        &:hover
          background-color: $orange2