// /wp-content/uploads/product-bg.png
[id^="module-pg-blue"]
  .kc_single_image
    height: 100%
    img
      display: block
      width: 100%
      height: 100%
      object-fit: cover
  .kc_text_block
    padding-left: 180px
    position: relative
    z-index: 2
    +tablet
      padding: 0
    &.title.left
      +tablet
        h2
          margin-left: -30px
  .kc_row_inner
    margin-bottom: 70px
    +tablet
      margin-bottom: 30px
    &:last-of-type
      margin-bottom: 0
    >div:first-of-type
      position: relative
      +tablet
        padding-bottom: 0
      .kc-col-inner-container
        display: flex
        justify-content: center
        flex-direction: column
        padding: 50px 0
        +tablet
          padding: 50px 50px 50px 70px
          background-image: linear-gradient(to left,#4D6EB2 0%,#11337B 100%)
      &:before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        +size(60vw,100%)
        z-index: 2
        background-image: url(/wp-content/uploads/about_us-bg.png)
        background-size: 100% 100%
        background-position: right top
        +tablet
          display: none
    &.reverse
      >div:first-of-type
        &:before
          left: auto
          right: 0
          transform: rotate(180deg)
        .kc-col-inner-container
          +tablet
            padding: 50px
      .kc_text_block
        padding-left: 50px
        padding-right: 150px
        +tablet
          padding: 0
  
[id^="module-pg-about"]
  background-image: url(/wp-content/uploads/product-bg.png)
  background-size: cover
  background-position: center center

[id^="module-pg-milestone"]
  background-image: url(/wp-content/uploads/milestone-bg.png)
  background-size: cover
  
[id^="module-pg-certificate"]
  margin-bottom: -70px
  background-image: url(/wp-content/uploads/product-bg.png)
  background-size: contain
  background-repeat: no-repeat
  background-position: 0 100%
  +tablet
    background-image: linear-gradient(to bottom, transparent 0%, transparent 10%,$main 50%,$darkBlue 100%)
  .certificate-row
    display: flex
    margin-left: -10px
    width: calc(100% + 20px)
    >div
      padding: 0 10px
      margin-bottom: 40px
      +tablet
        width: calc(100% / 2)
        margin-bottom: 20px
  img
    display: block
    width: 100%

[id^="module-pg-contact"]
  .wpcf7-form
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-left: -8px
    width: calc(100% + 16px)
    h3
      margin-bottom: 35px
      width: 100%
      +font(1.375,$gray,0)
        font-family: $font-title
        text-transform: capitalize
        text-align: center
  .input-row
    width: 100%
    position: relative
    margin-bottom: 20px
    padding: 0 8px
    &.last
      margin-bottom: 0
    &.half
      width: 50%
      +phone
        width: 100%
    br
      display: none
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="text"],
    select,
    textarea
      padding: 15px 25px 15px 135px
      width: 100%
      background-color: #F0F0F0
      border: none!important
      box-shadow: none
      outline: none
      +font(1.125,$gray,0)
        line-height: normal
      &::placeholder
        white-space: normal
    label
      margin: 0
      padding: 15px 25px
      position: absolute
      top: 0
      left: 8px
      z-index: 2
      width: fit-content
      +font(1.125,$gray,0)
        line-height: normal
        font-family: $font-title
        font-weight: normal
        text-transform: capitalize
      i
        color: $special
    input[type="tel"]
      padding-left: 90px
    input#contact-website
      padding-left: 220px
    p
      margin: 0
      +font(0.875,$gray,0)
        text-transform: capitalize
        font-family: $font-title
        font-weight: normal
  .btns
    position: relative
    .wpcf7-submit
      display: block
      float: none
      margin: 0 auto
      padding: 10px 60px 10px 35px
      width: fit-content
      border-radius: 4px
      background-color: $orange2
      +font(1.125,#fff,0)
        line-height: normal
        text-transform: capitalize
        text-decoration: none
    &:after
      content: ""
      display: inline-block
      margin-left: 15px
      margin-bottom: 2px
      +size(8px)
      border-top: solid 2px #fff
      border-right: solid 2px #fff
      transform: rotate(45deg)
      position: absolute
      bottom: calc(50% + 4px)
      right: 25px

[id^="module-map"]
  padding-top: 180px
  padding-bottom: 70px
  margin-top: 80px
  margin-bottom: -70px
  background-image: url(/wp-content/uploads/product-bg.png)
  background-size: 100% auto
  background-repeat: no-repeat
  background-position: 0 100%
  +tablet
    background-image: linear-gradient(to bottom, transparent 0%, transparent 10%,$main 50%,$darkBlue 100%)
  .kc-row-container.kc-container
    margin-top: -280px