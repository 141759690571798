//color
$main: #4D6EB2
$blue: #4379E8
$darkBlue: #124C99
$black: #000000
$orange: #FD5200
$orange2: #F68406
$gray: #313233
$text: #333
$link: #0d3f67
$special: #cb3747
$note: #8fae1b

$font-title: 'Open Sans',sans-serif
$font-normal: 'Source Sans Pro'

=mobile($mobile:1140px)
  @media screen and (max-width: $mobile)
    @content

=tablet($tablet:768px)
  @media screen and (max-width: $tablet)
    @content

=phone($phone:640px)
  @media screen and (max-width: $phone)
    @content

=mini($min:500px)
  @media screen and (max-width: $min)
    @content

=max($size)
  @media screen and (max-width: $size)
    @content

=min($size)
  @media screen and (min-width: $size)
    @content

//mixin
=size($w,$h:$w)
  width: $w
  height: $h

=clearfix  
  &:after
    content: ""
    display: table
    clear: both

=t($t:0.5)
  transition-duration: #{$t}s

=clr($c:#222)  
  color: $c

=font($size:1,$color:#000,$space:.2)
  font-size: #{$size}rem
  color: $color
  letter-spacing: #{$space}rem
  @content
  // +tablet
  //   font-size: #{$size*.95}rem
  //   letter-spacing: #{$space*.95}rem
  // +phone
  //   font-size: #{$size*.9}rem
  //   letter-spacing: #{$space*.9}rem
  // +mini
  //   font-size: #{$size*0.75}rem
  //   letter-spacing: #{$space*.75}rem
  
=container
  display: block
  margin: 0 auto
  padding: 0
  max-width: 1110px
  width: calc(100% - 170px)
  +mobile
    width: calc(100% - 80px)
  @content


=animate_delay($n:4,$cut:0.2)
  @for $i from 0 through 100
    &:nth-of-type(#{$i+1})
      animation-delay: #{$i%$n*$cut}s

=mask
  &:before
    content: ""
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000,.7)
    z-index: 99999
  &.switch-active
    overflow: hidden
    &:before
      display: block
    @content