
// header
.fa-angle-down:before
  +mobile
    content: "+"

.onepress-menu .nav-toggle-dropdown > .nav-toggle-subarrow .fa-angle-down.fa-angle-down:before
  +mobile
    content: "-"!important
    color: $black

.site-header .container
  position: static
  +mobile
    width: calc(100% - 60px)

.site-header
  line-height: normal
  background-color: rgba(#fff,1)
  +mask
    overflow: visible
  .header-right-wrapper
    float: right

#header-section
  position: fixed
  top: 0
  left: 0
  z-index: 1000
  width: 100%

#site-navigation
  +clearfix
  
.site-branding
  padding: 18px 0
  +t(.3)
  +mobile
    &.switch-active
      opacity: 0
  .site-text-logo
    +size(142px,34px)
    +font(0,transparent,0)
      display: block
    background-image: url(/wp-content/uploads/header-SUPERON_LOGO.svg)
    background-size: contain
    background-position: center center
    background-repeat: no-repeat
      

.main-navigation
  position: relative
  z-index: 99999
  +mobile
    position: absolute
    top: 0
    left: 0px
    width: 100%
  .onepress-menu
    +mobile
      border: none
      // 左側版本
      // padding-top: 20px
      // position: fixed
      // left: -250px
      // top: 0
      // height: 100vh
      // max-width: 250px
      // +t(.5)
      // &.switch-active
      //   left: 0
    // default
    li
      border: none
      +mobile
        width: 100%
      a
        text-decoration: none
        font-family: inherit
        font-weight: normal
        line-height: normal
        border: none
        text-transform: none
        +t(.3)
      .nav-toggle-subarrow
        padding: 10px 30px
        +t(.3)
        +font(1,$black,.1)
          line-height: normal
        +mobile
          display: block
          border: none
          position: absolute
          top: 0
          right: 0
          width: 100%
          text-align: right
          z-index: 2
          cursor: pointer
      // &:hover
      //   .nav-toggle-subarrow
      //     color: #fff
      // 第二層
      ul.sub-menu
        border: none
        width: 100%
        min-width: 170px
        left: 50%
        transform: translateX(-50%)
        +mobile
          position: relative
          display: none
          top: 0
          left: 0
          width: 100%
          transform: translateX(0%)
          background-color: $main
        &>li
          background: transparent
          &>a
            +font(.85,$black,.05)
            padding: 10px 20px
            +mobile
              background: transparent
              text-align: left
              color: #fff
              padding: 10px 30px
            &:hover
              color: #fff
              background-color: $main
        ul.sub-menu
          left: 100%
          transform: none
          +mobile
            left: 0
      &.nav-toggle-dropdown
        > ul.sub-menu
          display: block
          animation: fade .3s both
    // 第一層
    &>li
      &>a
        padding: 25px 35px
        +font(1,$black,0)
        +mobile
          padding: 10px 30px
        &.wpmenucart-contents
          padding: 22.5px 20px
          font-size: 12px
          letter-spacing: 1px
          +mobile
            padding: 12.5px 30px
      &.menu-item-has-children
        >a
          position: relative
          &:after
            content: ""
            display: block
            +size(0)
            border-top: solid 7px $orange
            border-left: solid 4px transparent
            border-right: solid 4px transparent
            position: absolute
            right: calc(50% - 4px)
            bottom: 12px
            +t(.3)
            opacity: 0
            +mobile
              display: none
      &.first-icon
        margin-left: 40px
        +mobile
          padding: 17.5px 30px
      &.icon>a
        padding: 17.5px 20px
        background-color: transparent
        img
          display: block
          +size(29px,auto)
      &:hover>a
        &:after
          opacity: 1


#nav-toggle
  margin: 0
  position: absolute
  top: 50%
  right: 25px
  z-index: 999999
  +size(35px)
  transform: translateY(-50%)
  span
    border-radius: 50px
    &:before,&:after
      transform-origin: 50% 50%
      border-radius: 50px
  &.nav-is-visible
    background-color: rgba(#fff,.3)
    border-radius: 50%
    span
      &:before,&:after
        top: 0
        left: 0
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)

#nav-button
  display: none
  margin: 0
  position: absolute
  top: 50%
  right: 25px
  z-index: 1000
  transform: translateY(-50%)
  +size(35px)
  font-size: 0
  z-index: 999999
  +mobile
    display: block
  span
    display: block
    margin: 16px auto
    +size(25px,3px)
    background-color: $black
    position: relative
    border-radius: 50px
    +t(.3)
    &:before,&:after
      content: ""
      display: block
      +size(25px,3px)
      background-color: $black
      transform-origin: 50% 50%
      position: absolute
      left: 0
      +t(.3)
      border-radius: 50px
    &:before
      top: -8px
    &:after
      top: 8px
  &.switch-active
    span
      background-color: transparent
      &:before,&:after
        top: 0
        left: 0
        background-color: #fff
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)